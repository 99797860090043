.detail-item {
  min-width: 400px;
  @media screen and (max-width: 768px) {
    min-width: 290px;
  }
  @media screen and (max-width: 425px) {
    min-width: 290px;
  }
  .detail-file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
