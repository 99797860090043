@import "./../../../../scss//variables.scss";

.basic-info {
  border-radius: 15px;
  background: #fff;
  padding: 12px;
  top: 10px;
  box-shadow: $box-shadow;
  .name {
    border-radius: 12px;
    background: #f9b571;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.05);
    padding: 12px;
    margin-bottom: 10px;
    .name-text {
      font-size: 18px;
      text-align: left;
      color: #fff;
    }
    .number-text {
      font-size: 14px;
      text-align: left;
      color: #fff;
    }
  }
  .info-wrapper {
    display: block;
    padding: 6px 0 6px 0;
    .info-label {
      font-size: 13px;
      color: #a2a2c3;
      margin-bottom: 4px;
    }
    .info-value {
      font-size: 14px;
      color: #323c47;
    }
  }
}

.flex {
  display: flex;
  & .flex-grow {
    flex-grow: 1;
  }
}