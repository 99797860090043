.filter-dates {
  display: flex;
}
.products-list {
  margin-top: 16px;
  .product-heading {
    display: flex;
    justify-content: space-between;
  }
}
.width30 {
  justify-content: space-between;
  .MuiFormControl-root {
    width: 33%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  .MuiAutocomplete-root {
    width: 33% !important;
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
    @media screen and (max-width: 425px) {
      width: 100% !important;
    }
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.table-wrapper-inner {
  margin: 10px;
}
.expanded-row {
  background-color: #f8f8f8;
  > td {
    box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  }
}

.reject-product {
  .filter-heading {
    display: flex;
    justify-content: center;
    align-self: center;
    color: #5e81f4;
    .filter-title {
      font-weight: 600;
    }
    .close-icon {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .MuiDialogContent-root {
    padding: 0 12px;
    border-bottom: none;
    .product-item {
      padding: 25px 15px 15px;
      border-bottom: 1px solid #cccccc;
      &:last-child {
        border-bottom: none;
      }
      .MuiGrid-root {
        padding: 0 12px;
      }
      .MuiTypography-colorError {
        color: #ff808b;
      }
    }
  }
  .add-fab-button {
    width: 40px;
    height: 40px;
    background-color: #44c4a1;
    margin: 10px 12px;
    &:hover,
    &:focus {
      background-color: #33ac8c;
    }
  }
  .product-footer {
    padding: 15px 24px;
    button {
      font-weight: 600;
      min-width: 120px;
      min-height: 40px;
      font-size: 12px;
      &.blue {
        margin-left: 15px;
      }
    }
  }
}

.inward-detail-section {
  .MuiTabs-root {
    .MuiTabs-flexContainer {
      &::before {
        content: "";
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        background: #eee;
        bottom: 0;
      }
      .MuiTab-wrapper {
        text-transform: capitalize;
      }
    }
    .MuiTabs-indicator {
      background-color: #33ac8c;
    }
  }
  .MuiBox-root {
    background: #f8f8f8;
    padding: 0;
    .MuiPaper-root {
      padding: 20px 30px;
      margin-bottom: 15px;
      &.table-wrapper {
        padding: 10px 0;
        margin-bottom: 0;
        .MuiTableCell-head {
          color: #a2a2c3;
        }
        tbody {
          tr {
            td {
              word-break: break-word;
              white-space: normal;
            }
            &:last-child {
              border-bottom: none;
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    h4.reject-stock {
      margin: 15px 5px;
    }
  }
}

.detail-dropdown-menu {
  top: 110px !important;
  left: initial !important;
  right: 130px !important;
  .MuiMenu-list {
    .MuiButtonBase-root {
      font-size: 0.8rem;
    }
  }
}
