.progress-wrapper {
  border-radius: 7px;
  background: #f8f8f8;
  position: relative;
  padding: 20px;
  height: 60;
  .cross {
    width: 12px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
  .ProgressBar {
    width: 100%;
    height: 2px;
    background-color: #ededf7;
  }

  .Progress {
    background-color: #4d4cac;
    height: 100%;
    margin: 0;
  }
  .text {
    font-weight: normal;
    font-size: 9px;
    line-height: 22px;
    color: #4d4cac;
    text-align: right;
  }
  .filename {
    font-size: 12px;
    line-height: 22px;
    color: #323c47;
  }
  &.completed {
    .Progress {
      background-color: #44c4a1;
    }
    .text {
      color: #44c4a1;
    }
  }
}
