@import "./../../../scss//variables.scss";
.lead-info {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
  padding: 20px;
  padding-right: 0;
  background: transparent;
  height: auto !important;
  .stepper {
    .MuiPaper-root {
      box-shadow: $box-shadow;
    }
  }
  .heading-wrapper {
    display: flex;
    align-items: center;
  }
  .page-heading {
    font-family: Montserrat;
    font-weight: bold;
    font-size: $font-heading;
    color: #323c47;
  }
  .back-icon {
    color: #323c47;
    padding: 4px;
  }
  .lead-section-heading {
    font-weight: 500;
    font-size: 16px;
    color: #323c47;
    margin: 10px 0;
  }
  .password-checkbox {
    margin-right: 26px;
    .MuiCheckbox-root {
      padding: 1px;
    }
    .MuiTypography-body1 {
      font-weight: 300;
      font-size: $font-normal;
      line-height: 22px;
      text-align: left;
      color: #323c47;
    }
  }
  .left-info {
    width: 100%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      .MuiStepLabel-labelContainer {
        width: 60px;
        overflow-wrap: anywhere;
      }
      .MuiStep-horizontal {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    @media screen and (max-width: 425px) {
      .MuiStepLabel-labelContainer {
        width: 60px;
        overflow-wrap: anywhere;
      }
      .MuiStep-horizontal {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .tabs {
    background: white;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: $box-shadow;
    .Mui-disabled {
      opacity: 0.5;
      pointer-events: all;
      cursor: not-allowed;
    }
    .MuiTab-root {
      text-transform: none;
      font-family: Montserrat;
      font-weight: normal;
      font-size: $font-normal;
      line-height: 22px;
      text-align: left;
      color: #a2a2c3;
    }
    .MuiTab-textColorPrimary.Mui-selected {
      color: #ff808b;
    }
    .MuiTabs-indicator {
      background-color: #ff808b;
    }
    .MuiTabs-flexContainer {
      border-bottom: 1px solid #a2a2c3;
    }
    @media screen and (max-width: 768px) {
      .MuiTabs-scroller {
        overflow: scroll !important;
      }
    }
    @media screen and (max-width: 425px) {
      .MuiTabs-scroller {
        overflow: scroll !important;
      }
    }
  }
  .pinned-note-wrapper {
    border-radius: 15px;
    background: #fff;
    padding: 20px;
    box-shadow: $box-shadow;
  }
  .filter-badge {
    display: flex;
    justify-content: space-around;
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      max-width: 100%;
      overflow: scroll;
    }
    @media screen and (max-width: 425px) {
      justify-content: flex-start;
      max-width: 100%;
      overflow: scroll;
    }
    .badge {
      font-size: $font-normal;
      color: #a2a2c3;
      cursor: pointer;
      padding: 3px 15px;
      display: flex;
      align-items: center;
      height: 28px;
      &.active {
        border-radius: 13px;
        background: #6956cf;
        color: white;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  .right-info {
    width: 28%;
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  .top-right-info{
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      margin-top: 20px;
    }
  }
  .MuiStepper-root {
    border-radius: 15px;
    padding: 12px 0;
  }
  .icon {
    color: #a2a2c3;
    display: flex;
    height: 18;
    align-items: center;
    z-index: 1;
    font-size: 18;
    background-color: white;
    &.active {
      color: #44c4a1;
      z-index: 1;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 10px;
    font-size: 12px;
  }
  .MuiStepLabel-label.MuiStepLabel-completed,
  .MuiStepLabel-label.MuiStepLabel-active {
    color: #44c4a1;
  }
  .MuiStep-completed {
    .MuiSvgIcon-root {
      color: #44c4a1;
      z-index: 1;
    }
  }
  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 12px);
    right: calc(50% + 12px);
    color: #44c4a1;
  }
  .MuiStepConnector-active,
  .MuiStepConnector-completed {
    .MuiStepConnector-line {
      background-color: #44c4a1;
    }
  }
  .MuiStepConnector-line {
    border: 0;
    height: 2px;
    border-radius: 1px;
    background-color: #a2a2c3;
  }
}

.add-documentPostSales{
  margin-right: 5px !important;
  width: 10%;
  align-self: flex-end;
  margin-top: -43px !important;
  margin-bottom: 10px !important;
}
.documentNameError{
  display: block;
  color: red;
}

.main-properties{
  display: inline-flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  .properties{
    width: 85%;
    display:flex;
    background:#fff;
    padding: 1px 5px 1px 0px;
    border-radius: 10px;
    margin-right: 5px;
    height: 42px;
    .property{
      width: 30%;
      border-radius: 10px;
      text-align: center;
      height: 100%;
      padding-top: 10px;
      font-size: 16px;
      color:rgba(0, 0, 0, 0.54);
    }
    .selected{
      background-color:#ff808b;
      color:#fff;
    }
  }
  .addProperty{
    width:16%;
  }
}

.propertyDetails{
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  position:relative;
  @media screen and (max-width: 768px) {
    flex-direction: column;
}
  .actionButtons{
    position: absolute;
    right: 8px;
    top:0px;
    display:inherit;
   
    .MuiSvgIcon-root{
      width: 18px;
      height: 18px;
    margin: 4px;
    cursor: pointer;
    }
  }
  .rightPane{
    width:57%;
    .topPane{
      display: flex;
      font-size: 14px;
      margin-bottom: 10px;
      .dealMode{
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
      }
        border-radius: 30px;
        background-color: #f9b571;
        padding: 5px 10px 5px 10px;
        margin-right: 10px;
        color: #fff;
        font-size: 12px;
      }
      .dealDate{
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
      }
    }
    .bottomPane{
      .dealName{
        margin-bottom: 5px;
        margin-left: 7px;
        .propertyName{
          color: #ff808b;
          margin-right: 5px;
          font-size: 18px;
        }
        .propertyType{
          font-size: 16px;
        }
      }
      .propertyDescription{
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
  .leftPane{
    width:43%;
    display:flex;
    @media screen and (max-width: 768px) {
      width: 100%;
  }
    .header{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
    }
    .totalAmount{
      border-left: 1px solid rgba(0, 0, 0, 0.24);
      padding: 10px 8px;
      text-align: center;
      .amount{
        color: #ff808b;
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
    .recievedAmount{
      border-left: 1px solid rgba(0, 0, 0, 0.24);
      padding: 10px 8px;
      text-align: center;
      .amount{
        color: #44c4a1;
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
    .pendingAmount{
      border-left: 1px solid rgba(0, 0, 0, 0.24);
      padding: 10px 8px;
      text-align: center;
      .amount{
        color: #f9b571;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}