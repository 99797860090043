.debit-note {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: -1px;

    &.top-table {
      margin-top: 0;
    }
  }
  .width20 {
    width: 20%;
  }
  .width40 {
    width: 40%;
  }
  .middle {
    text-align: center;
  }
  .no {
    width: 35px;
  }
  .date {
    width: 150px;
  }
  .desc {
    width: 200px;
  }
  .quantity {
    width: 90px;
  }
  .uom {
    width: 80px;
  }
  .remarks,
  .rate,
  .amount {
    width: 10%;
  }
  .footer {
    border: 1px solid black;
    border-top: none;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }
  .align-right {
    text-align: right;
  }
  td,
  th {
    word-wrap: break-word;
    border: 1px solid black;
    white-space: normal;
  }
}


/* Center the logo within its container */
.logo-container {
  margin-top: 20px; /* Adjust margin as needed */
  text-align: center; /* Center horizontally */
  padding-bottom: 20px;
}

.logo {
  width: auto; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  /* Margin is auto horizontally to center the image */
  margin: 0 auto;
}

@media print {
  body > .debit-note * {
    visibility: visible !important;
  }

  body > .debit-note {
    display: block !important;
    visibility: visible !important;
  }
}
@page {
  size: auto;
  margin: 10mm;
}
