.project-page {
  background-color: #f8f8f8;
  margin: 20px;
  .image {
    width: 250px;
    height: 120px;
    border-radius: 15px;
  }
  .project-wrapper {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.06);
    padding: 20px;
    padding-bottom: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    color: #323c47;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 16px;
    text-align: center;
    min-width: 200px;
  }
}
