.title {
  font-weight: bold;
  font-size: 18px;
  color: #323c47;
  padding: 0px 0px 8px 0px;
}

.crmdashboard-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  width: 85vw;
  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    height: 100%;
    width: 100%;
  }
  #semiPieChart {
    height: calc(100% - 28px) !important;
  }
  .top,
  .bottom {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
    padding: 12px;
    .left {
      width: calc(30% - 20px);
      margin-right: 20px;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 12px;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 12px;
      }
    }
    .right {
      width: 70%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }
  }
  .top {
    height: 65% !important;
    @media screen and (max-width: 768px) {
      height: auto;
    }
    @media screen and (max-width: 425px) {
      height: auto;
    }
  }
  .bottom {
    height: 35% !important;
    .right,
    .left {
      display: flex;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      @media screen and (max-width: 425px) {
        flex-direction: column;
      }
    }

    .customerPipeline {
      margin-right: 20px;
    }
    .conversionRatio {
      margin-right: 20px;
    }
    .customerPipeline,
    .conversionRatio,
    .stagnatedleads {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 12px;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
        margin-bottom: 12px;
      }
    }
    .notification {
      background: #fff;
      box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      .notification-content {
        overflow: auto;
        box-shadow: none !important;
        background: none !important;
        border-radius: 0px !important;
        td {
          white-space: nowrap;
        }
      }
    }
  }
  .customerPipeline,
  .conversionRatio,
  .stagnatedleads,
  .topPerformer {
    width: 100%;
    .performer {
      color: #f9b571;
    }
    .customer-pipeline {
      color: #4d4cac;
    }
    .conversion {
      color: #4d4cac;
    }
    .stagnant {
      color: #323c47;
    }
    .dashboard-heading {
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 12px;
      margin-left: 10px;
      height: 18px;
      padding-top: 5px;
    }
    .notification-content {
      padding-bottom: 0;
    }
    th {
      font-weight: normal;
      font-size: 12px;
      color: #a2a2c3;
      line-height: 22px;
      height: 35px;
    }

    td {
      line-height: 22px;
      font-size: 16px;
      color: #323c47;
      height: 35px;
      font-weight: 300;
    }
  }
}

.crmdashboard-wrapper {
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  scrollbar-width: thin;
}

.topPerformer-details {
  height: 88%;
  .name {
    font-size: 30px;
    color: #323c47;
    padding-left: 10px;
  }

  .top {
    margin-top: 8px !important;
  }
  .top,
  .bottom {
    height: 40%;
    padding: 0px;

    .left,
    .right {
      width: 50%;
      display: block;
      margin: 0px 0px 0px 10px;
      .header {
        font-size: 18px;
        color: #323c47;
      }
      .count {
        font-size: 60px;
        padding-left: 20px;
      }
      .leadgenerated {
        color: #44c4a1;
      }
      .negotiation {
        color: #f9b571;
      }
      .dealclose {
        color: #ff808b;
      }
      .propertyvisit {
        color: #4d4cac;
      }
    }
  }
}

.box {
  background: #fff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.leftPanel {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
  @media screen and (max-width: 425px) {
    padding-bottom: 10px;
  }
  .pipeline {
    .heading {
      color: #ff808b;
      font-size: 13px;
    }
  }

  .active {
    color: #5e81f4 !important;
    .infoIcon {
      width: 19%;
      margin-top: -3px;
      .MuiIconButton-root {
        padding: 0 !important;
        color: #5e81f4 !important;
      }
    }
    .selected {
      background-color: #5e81f4 !important;
    }
  }
  .pipeline-detail {
    display: flex;
    margin-top: 8px;
    color: #323c47;
    font-size: 15px;
    .header {
      width: 60%;
      cursor: pointer;
    }
    .count {
      width: 20%;
    }
    .infoIcon {
      width: 19%;
      margin-top: -3px;
      .MuiIconButton-root {
        padding: 0 !important;
      }
    }
    .selected {
      width: 1%;
    }
  }
  .activities {
    .heading {
      color: #44c4a1;
      font-size: 13px;
    }
  }
  .activity-detail {
    display: flex;
    margin-top: 10px;
    color: #323c47;
    font-size: 15px;
  }
}

.chartwrapper {
  height: 100%;
  @media screen and (max-width: 768px) {
    height: 210px;
  }
  @media screen and (max-width: 425px) {
    height: 210px;
  }
  .topchart {
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
    }
  }
  .leftPanel.heading {
    // float: left;
    color: #323c47;
    font-size: 15px;
    // padding: 5px 0px 0px 10px;
    width: unset;
    height: unset;
    @media screen and (max-width: 768px) {
      padding: 5px 0px 0px 10px;
    }
  }
  .rightPanel {
    // float: right;
    // margin-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      justify-content: flex-end;
    }
    .navigation-type {
      color: #a2a2c3;
      // padding-top: 1px;
      .fullform {
        display: block;
      }
      .shortform {
        display: none;
      }
      @media screen and (max-width: 768px) {
        .fullform {
          display: none;
        }
        .shortform {
          display: block;
        }
      }
    }
    .month {
      color: #a2a2c3;
      font-size: 13px;
      // padding: 5px 0px 0px 10px;
    }
  }
  .chartDetail {
    height: 90%;
    @media screen and (max-width: 768px) {
      height: auto;
      padding-top: 10px;
    }
    @media screen and (max-width: 425px) {
      height: auto;
      padding-top: 10px;
    }
  }
}
