@import "./scss/common.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import "./scss//variables.scss";
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}

.MuiIconButton-root.date-button {
  padding: 0px;
}
.content {
  width: 1557px;
  border-radius: 15px;
  background: #fff;
}

.add-button.MuiButton-root {
  height: 40px;
  border-radius: 7px;
  background: #5e81f4;
  padding: 0 14px;
}
.top-button-wrapper {
  display: flex;
  button {
    margin: 0 5px;
  }
}
.add {
  .flex {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
    }
    .MuiAutocomplete-root {
      width: 100%;
    }
    .MuiTextField-root {
      padding-right: 20px;
      @media screen and (max-width: 768px) {
        padding-right: 0px;
      }
      @media screen and (max-width: 425px) {
        padding-right: 0px;
      }
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .width50 {
    .MuiFormControl-root {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }
    .MuiAutocomplete-root {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      @media screen and (max-width: 425px) {
        width: 100%;
      }
      .MuiTextField-root {
        width: 100%;
      }
    }
  }
}
