@import "./../../scss/variables.scss";

.stock.detail-section {
  .detail-item {
    display: flex;
    align-items: center;
    .label {
      margin-right: 10px;
    }
  }
}
.filter-dates.full-width{
  > .MuiFormControl-root{
    width:100%;
  }
}
.low {
  font-size: $font-normal;
  justify-content: center;
  color: #f9b571;
  width: 70px;
  height: 30px;
  border-radius: 18px;
  background: #fff8f0;
  display: inline-flex;
  align-items: center;
}
.high {
  font-size: $font-normal;
  justify-content: center;
  color: #44c4a1;
  width: 70px;
  height: 30px;
  border-radius: 18px;
  background: #ecf9f5;
  display: inline-flex;
  align-items: center;
}
