.note-tab {
  .MuiFormControl-marginNormal {
    margin: 0;
    margin-bottom: 16px;
  }
  fieldset {
    border: none;
  }
  .blue {
    width: 100px;
    height: 45px;
    border-radius: 7px;
  }

  .button-section {
    display: flex;
    justify-content: flex-end;
    .blue {
      height: 38px;
      width: 90px;
      font-weight: bold;
      font-size: 18px;
      color: #fff;
    }
  }
}
