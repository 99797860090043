@import "./../../scss/variables.scss";

.accordion-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #007bff;
}

.accordion-icon {
  margin-right: 8px;
  font-size: 20px;
}

.details-tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
}

.tab-button {
  background: none;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}

.tab-button.active {
  border-bottom: 2px solid #007bff;
  color: #0056b3;
}

.content-container {
  max-height: 80vh; /* Adjust based on your design */
  overflow-y: auto;
  padding: 16px;
}

.details-main-content {
  padding: 16px;
}

.history-content {
  padding: 16px;
}

.details-main-content table,
.history-content table {
  width: 100%;
  border-collapse: collapse;
}

.details-main-content th,
.details-main-content td,
.history-content th,
.history-content td {
  border: 1px solid #ddd;
  padding: 8px;
}

.details-main-content th,
.history-content th {
  background-color: #f4f4f4;
}


.details-tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
}

.tab-button {
  background: none;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}

.tab-button.active {
  border-bottom: 2px solid #007bff;
  color: #0056b3;
}

.history-content {
  padding: 16px;
}

.history-content table {
  width: 100%;
  border-collapse: collapse;
}

.history-content th, .history-content td {
  border: 1px solid #ddd;
  padding: 8px;
}

.history-content th {
  background-color: #f4f4f4;
}


.stock.detail-section {
  .detail-item {
    display: flex;
    align-items: center;
    .label {
      margin-right: 10px;
    }
  }
}
.filter-dates.full-width{
  > .MuiFormControl-root{
    width:100%;
  }
}
.low {
  font-size: $font-normal;
  justify-content: center;
  color: #f9b571;
  width: 70px;
  height: 30px;
  border-radius: 18px;
  background: #fff8f0;
  display: inline-flex;
  align-items: center;
}
.high {
  font-size: $font-normal;
  justify-content: center;
  color: #44c4a1;
  width: 70px;
  height: 30px;
  border-radius: 18px;
  background: #ecf9f5;
  display: inline-flex;
  align-items: center;
}
