.project-plan-container {
    position: relative;
    @media screen and (max-width: 768px) {
        margin-top: 12px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 12px;
    }
    .project-plan-plot {
        border-radius: 15px;
        padding: 15px 25px;
        background-color: #ffffff;
        margin-bottom: 15px;
        .property-type-title {
            font-weight: 600;
            font-size: 16px;
        }
        .add-property {
            padding-top: 20px;
            form {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @media screen and (max-width: 768px) {
                  flex-direction: column;
                }
                @media screen and (max-width: 425px) {
                  flex-direction: column;
                }
                .MuiFormControl-root {
                    width: auto;
                    min-width: 300px;
                    margin: 0;
                }
                button {
                    margin-left: 15px;
                    @media screen and (max-width: 768px) {
                      margin-top: 12px;
                    }
                    @media screen and (max-width: 425px) {
                      margin-top: 12px;
                    }
                    text-transform: uppercase;
                    font-weight: 600;
                    min-width: 100px;
                }
            }
        }
        .property-subtypes {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 10px;
            .property-subtypes-items {
                margin: 5px;
                button {
                    border-radius: 6px;
                    background: #f9b571;
                    padding: 5px 0px 5px 15px;
                    font-size: 12px;
                    text-transform: capitalize;
                    color: #fff;
                    &.isBooked {
                        background: #2ca92a;
                    }
                    &.no-edit{
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}
.property-subtypes-dropdown {
    .MuiPaper-root {
        box-shadow: 0 0 2px #f1f1f1;
        li {
            font-size: 12px;
        }
    }
}

.project-property-add {
    form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .width50 {
            justify-content: center;
            width: 70%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            @media screen and (max-width: 425px) {
                width: 100%;
            }
        }
    }
}
.property-counter {
    margin-left: 10px;
    p {
        margin-bottom: 5px;
        font-weight: 600;
        letter-spacing: 1px;
        &.red {
            color: #2ca92a;
        }
        &.green {
            color: #101010;
            padding-left: 5px;
        }
    }
}

.right-header{
    button{
        margin-left: 15px;
    }
}
.add-property-plus {
    display: none;
}
@media screen and (max-width: 768px) {
    .add-property-plus {
        display: inline-block;
        font-size: 40px;
    } 
    .add-property-text{
        display: none;
    }
}