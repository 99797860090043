.dashboard-item {
  .MuiTreeItem-content {
    flex-direction: row-reverse;
  }
  &.Mui-selected {
    > .MuiTreeItem-content {
      border-right: 3px solid #5f8172;
    }
  }
  &.Mui-expanded {
    .MuiTreeItem-iconContainer {
      color: #ff808b;
    }
  }
  .MuiTreeItem-iconContainer {
    margin-right: 15px;
    color: #a2a2c3;
    svg {
      font-size: 22px;
    }
  }
  a {
    text-decoration: none;
  }
  .MuiTreeItem-label {
    padding-left: 0;
    &:hover {
      background-color: transparent;
      .name {
        color: #323c47;
      }
    }
  }
  .menu-item {
    padding: 8px 10px;
    display: flex;
    align-items: center;
  }
  .MuiCollapse-wrapperInner {
    margin-left: 18px !important;
  }
  .icon {
    margin-right: 13px;
    color: #a2a2c3;
  }
  .name {
    flex-grow: 1;
    font-size: 14px;
    letter-spacing: 0.08em;
    text-align: left;
    font-weight: 600;
    color: #8989aa;
  }
}
.active {
  .name {
    color: #5f8172 !important;
  }
  .icon {
    color: #5f8172;
  }
}
.Mui-selected {
  .MuiTreeItem-label {
    background-color: transparent !important;
  }
  .icon {
    color: #5f8172;
  }
}
