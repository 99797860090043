.login {
  .logo {
    height: 150px;
    max-width: 90vw;
    margin-bottom: 16px;
  }
  .login-form-wrapper {
    display: flex;
  }
  .login-img {
    width: 100%;
    max-height: 100vh;
    display: flex;
  }
  .img-wrapper {
    display: flex;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: auto;
    @media screen and (max-width: 768px) {
      padding: 0px 15px;
      max-width: 100%;
    }
    @media screen and (max-width: 425px) {
      padding: 0px 15px;
      max-width: 100%;
    }
  }
}
